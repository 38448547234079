import './style.css';
import ReactDOM from 'react-dom/client';
import React, { useState, useLayoutEffect, useEffect, useRef, Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import Experience from './Experience.jsx';
import LoadingScreen from './LoadingScreen.jsx';
import SlidersAndFov from './SlidersAndFov.jsx';
import { useProgress } from '@react-three/drei';
import { Howl, Howler } from 'howler';

// FUCK!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// THIS
// FILE

const isPortrait = () => window.matchMedia("(orientation: portrait)").matches;

const App = () => {
  // State Management
  const [synthwaveMode, setSynthwaveMode] = useState(false);
  const [asciiMode, setAsciiMode] = useState(false);
  const [audioInitialized, setAudioInitialized] = useState(false);
  const [volume, setVolume] = useState(0.2);
  const [portrait, setPortrait] = useState(isPortrait());
  const [fov, setFov] = useState(60);
  const [collapsed, setCollapsed] = useState(false);
  const [audioEnabled, setAudioEnabled] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [audioData, setAudioData] = useState(null);

  // Refs
  const eightBitSoundRef = useRef(null);
  const orchSoundRef = useRef(null);
  const analyserRef = useRef(null);
  const animationFrameRef = useRef(null);

  // Handle mode switching
  const handleButtonClick = () => {
    setSynthwaveMode((prevMode) => !prevMode);
    const shouldEnableAscii = Math.random() < 0.2;
    setAsciiMode(shouldEnableAscii);

    if (audioEnabled) {
      if (!synthwaveMode) {
        eightBitSoundRef.current?.volume(volume);
        orchSoundRef.current?.volume(0);
      } else {
        eightBitSoundRef.current?.volume(0);
        orchSoundRef.current?.volume(volume);
      }
    }
  };

  // Audio initialization and handling
  const handleAudioEnable = (event) => {
    setIsChecked(event.target.checked);

    if (event.target.checked) {
      try {
        // Initialize 8-bit track
        eightBitSoundRef.current = new Howl({
          src: ['/8bit_mp3.mp3'],
          loop: true,
          volume: synthwaveMode ? volume : 0,
          html5: false,
          preload: true,
        });

        // Initialize orchestral track
        orchSoundRef.current = new Howl({
          src: ['/orch_mp3.mp3'],
          loop: true,
          volume: synthwaveMode ? 0 : volume,
          html5: false,
          preload: true,
        });

        // Play both tracks
        eightBitSoundRef.current.play();
        orchSoundRef.current.play();

        // Setup audio analyzer
        const context = Howler.ctx;
        analyserRef.current = context.createAnalyser();
        analyserRef.current.fftSize = 512;
        Howler.masterGain.connect(analyserRef.current);

        // Start audio analysis
        const bufferLength = analyserRef.current.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        const updateAudioData = () => {
          analyserRef.current.getByteFrequencyData(dataArray);
          setAudioData({ dataArray: [...dataArray] });
          animationFrameRef.current = requestAnimationFrame(updateAudioData);
        };

        updateAudioData();

        setAudioEnabled(true);
        setAudioInitialized(true);
      } catch (error) {
        console.error('Error initializing audio:', error);
        setIsChecked(false);
        alert('Unable to enable audio. Please check your browser settings, or turn off your phone\'s silent mode and try again.');
      }
    } else {
      // Mute both tracks
      eightBitSoundRef.current?.volume(0);
      orchSoundRef.current?.volume(0);
      
      // Clean up audio analysis
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      if (analyserRef.current) {
        analyserRef.current.disconnect();
      }
      
      setAudioEnabled(false);
    }
  };

  // Volume control
  const handleVolumeChange = (event) => {
    const newVolume = parseFloat(event.target.value);
    setVolume(newVolume);

    if (audioEnabled) {
      if (synthwaveMode && eightBitSoundRef.current) {
        eightBitSoundRef.current.volume(newVolume);
        orchSoundRef.current.volume(0);
      } else if (orchSoundRef.current) {
        eightBitSoundRef.current.volume(0);
        orchSoundRef.current.volume(newVolume);
      }
    }
  };

  // FOV control
  const handleFovChange = (event) => {
    setFov(parseFloat(event.target.value));
  };

  // Audio mode effect
  useEffect(() => {
    if (audioEnabled) {
      if (synthwaveMode) {
        eightBitSoundRef.current?.fade(eightBitSoundRef.current.volume(), volume, 300);
        orchSoundRef.current?.fade(orchSoundRef.current.volume(), 0, 300);
      } else {
        eightBitSoundRef.current?.fade(eightBitSoundRef.current.volume(), 0, 300);
        orchSoundRef.current?.fade(orchSoundRef.current.volume(), volume, 300);
      }
    }

    if (asciiMode && !synthwaveMode) {
      setAsciiMode(false);
    }
  }, [synthwaveMode, volume, audioEnabled, asciiMode]);

  // Initial loading screen cleanup
  useEffect(() => {
    const loadingScreen = document.getElementById('initial-loading-screen');
    if (loadingScreen) {
      loadingScreen.style.transition = 'opacity 1s ease-out';
      loadingScreen.style.opacity = 0;
      setTimeout(() => {
        loadingScreen.remove();
      }, 1000);
    }
  }, []);

  // Viewport height fix
  useLayoutEffect(() => {
    const fixViewportHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    fixViewportHeight();
    window.addEventListener('resize', fixViewportHeight);
    window.addEventListener('orientationchange', fixViewportHeight);

    return () => {
      window.removeEventListener('resize', fixViewportHeight);
      window.removeEventListener('orientationchange', fixViewportHeight);
    };
  }, []);

  // Orientation and resize handling
  useEffect(() => {
    let resizeTimeout;
    const handleResize = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        setPortrait(isPortrait());
      }, 150);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    return () => {
      clearTimeout(resizeTimeout);
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  // Audio cleanup
  useEffect(() => {
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      if (analyserRef.current) {
        analyserRef.current.disconnect();
      }
      if (eightBitSoundRef.current) {
        eightBitSoundRef.current.fade(eightBitSoundRef.current.volume(), 0, 300);
        setTimeout(() => {
          eightBitSoundRef.current.unload();
          eightBitSoundRef.current = null;
        }, 300);
      }
      if (orchSoundRef.current) {
        orchSoundRef.current.fade(orchSoundRef.current.volume(), 0, 300);
        setTimeout(() => {
          orchSoundRef.current.unload();
          orchSoundRef.current = null;
        }, 300);
      }
    };
  }, []);

  const { active, progress } = useProgress();

  return (
    <div id="app-container" style={{ position: 'absolute', width: '100%', height: '100%', overflow: 'hidden' }}>
      <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, overflow: 'hidden' }}>
        {active && <LoadingScreen progress={progress} />}

        <Suspense fallback={null}>
          <Canvas
            className="r3f"
            camera={{
              fov: fov,
              near: 0.1,
              far: 600,
              position: [-3, 1.5, 4],
            }}
          >
            <Experience 
              synthwaveMode={synthwaveMode}
              asciiMode={asciiMode}
              fov={fov}
              audioData={audioData}
            />
          </Canvas>
        </Suspense>
        
        <div
          style={{
            position: 'fixed',
            right: '20px',
            bottom: '20px',
            maxWidth: '90%',
            zIndex: 10,
            display: 'flex',
            transition: 'all 0.3s ease',
            transform: collapsed ? 'translateX(100%)' : 'translateX(0)',
          }}
        >
          {/* Collapse Toggle Button */}
          <button
            onClick={() => setCollapsed(!collapsed)}
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              border: 'none',
              borderRadius: '8px 0 0 8px',
              padding: '12px 8px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              backdropFilter: 'blur(8px)',
              position: 'relative',
              left: collapsed ? '-30px' : '-5px',
              zIndex: 11,
            }}
          >
            <svg 
              width="20" 
              height="20" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="#fff"
              strokeWidth="2"
              style={{
                transform: collapsed ? 'rotate(0deg)' : 'rotate(180deg)',
                transition: 'transform 0.3s ease',
              }}
            >
              <path d="M15 18l-6-6 6-6" />
            </svg>
          </button>

          {/* Main Controls Panel */}
          <div
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              borderRadius: '8px',
              padding: '12px',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              backdropFilter: 'blur(8px)',
              visibility: collapsed ? 'hidden' : 'visible',
              opacity: collapsed ? 0 : 1,
              transition: 'visibility 0s linear 0.3s, opacity 0.3s linear',
            }}
          >
            <SlidersAndFov
              volume={volume}
              onVolumeChange={handleVolumeChange}
              fov={fov}
              onFovChange={handleFovChange}
              audioEnabled={audioEnabled}
              onAudioEnable={handleAudioEnable}
              isChecked={isChecked}
            />

            {/* Button Container */}
            <div style={{
              display: 'flex',
              gap: '10px',
              alignSelf: 'flex-end'
            }}>
              <button
                onClick={() => window.open('https://kaipandit.com', '_blank')}
                style={{
                  padding: '8px 16px',
                  fontSize: '14px',
                  cursor: 'pointer',
                  backgroundColor: '#ff69b4',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '5px',
                  whiteSpace: 'nowrap',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                  transition: 'all 0.2s ease',
                }}
                onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
              >
                About/Work
              </button>

              <button
                onClick={handleButtonClick}
                style={{
                  padding: '8px 16px',
                  fontSize: '14px',
                  cursor: 'pointer',
                  backgroundColor: '#ff69b4',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '5px',
                  whiteSpace: 'nowrap',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                  transition: 'all 0.2s ease',
                }}
                onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
              >
                {synthwaveMode ? 'Disable Retro Mode' : 'Enable Retro Mode'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const root = ReactDOM.createRoot(document.querySelector('#root'));
root.render(<App />);

export default App;