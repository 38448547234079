// LightContent.jsx

import React from 'react';
import AnimatedTitle from './AnimatedTitle.jsx';
import CyclingWorkPlaces from './CyclingWorkPlaces.jsx';
import Scene from './Scene.jsx';
import { Text } from '@react-three/drei';

export default function LightContent({ synthwaveMode, asciiMode, audioData }) { 
  const fixedScale = [1, 1, 1]; 

  return (
    <group scale={fixedScale}>
      <AnimatedTitle
        text="KAI PANDIT"
        position={[2, 0.9, 0.75]}
        rotation-y={-1.25}
      />
      {/* Other text components */}
      <Text
        font="/PixelifySans-Regular.ttf"
        fontSize={0.08}
        position={[-2.55, 1, 0]}
        rotation-x={-0.5}
        maxWidth={2}
        color={synthwaveMode ? '#00ffff' : 'black'}
      >
        {"kai's portfolio"}
      </Text>
      <Text
        font="/PixelifySans-Regular.ttf"
        fontSize={0.03}
        position={[0, 1.45, -2]}
        rotation={[0, Math.PI, 0]}
        maxWidth={2}
        color={synthwaveMode ? '#ff00ff' : 'black'}
      >
        {
          'can you read backwards??? then why are you looking at a backwards computer screen???? go away...'
        }
      </Text>

      <group position={[2, 0.04, 0.75]} rotation-y={-1.25}>
        <Text
          font="/PixelifySans-Regular.ttf"
          fontSize={0.1}
          maxWidth={2}
          color={synthwaveMode ? 'black' : 'black'}
        >
          {
            "Welcome to my site! I'm Rutgers '24, UT Austin '26, and ex-NASA and EA. You can see my about me, work, and a piece of my music here. Hit the Retro button :)"
          }
        </Text>
      </group>
      <CyclingWorkPlaces position={[2, 1.1, 1.95]} rotation-y={-1.25} />

      <Scene
        iframeLink="https://kaipandit.com/grass"
        sectionTitle="Home"
        synthwaveMode={synthwaveMode}
        asciiMode={asciiMode} 
        audioData={audioData}
      />

      {/* Glass plane */}
      <mesh position={[1, -0.71, 1.6]} rotation={[-Math.PI / 2, 0, 0]} renderOrder={1}>
        <planeGeometry args={[12, 11]} />
        <meshPhysicalMaterial
          color={'#ffffff'}
          roughness={0.1}
          metalness={0.1}
          clearcoat={1}
          clearcoatRoughness={0.1}
          opacity={synthwaveMode ? 0.05 : .6}
          transparent
          depthWrite={false} // Disable depth write to prevent depth buffer interference, because I definitely wanted that and knew what this was.
        />
      </mesh>

    </group>
  );
}
