// AnimatedTitle.jsx

import React, { useRef, useMemo } from 'react';
import { Text } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';

export default function AnimatedTitle({ text, position, ...props }) {
  const textRef = useRef();
  const particlesRef = useRef();

  const particles = useMemo(() => {
    const temp = [];
    for (let i = 0; i < 100; i++) {
      const x = (Math.random() - 0.5) * 2;
      const y = (Math.random() - 0.5) * 2;
      const z = (Math.random() - 0.5) * 2;
      temp.push({ x, y, z });
    }
    return temp;
  }, []);

  useFrame((state) => {
    if (textRef.current) {
      const elapsedTime = state.clock.getElapsedTime();
      const hue = (elapsedTime * 0.1) % 1;
      textRef.current.material.color.setHSL(hue, 1, 0.5);
    }
    if (particlesRef.current) {
      particlesRef.current.rotation.y += 0.01;
    }
  });

  return (
    <group position={position} {...props}>
      <Text
        ref={textRef}
        font="/PixelifySans-Regular.ttf"
        fontSize={0.5}
        maxWidth={2}
      >
        {text}
      </Text>
      <points ref={particlesRef}>
        <bufferGeometry>
          <bufferAttribute
            attachObject={['attributes', 'position']}
            count={particles.length}
            itemSize={3}
            array={new Float32Array(particles.flatMap((p) => [p.x, p.y, p.z]))}
          />
        </bufferGeometry>
        <pointsMaterial size={0.015} color="#ffff00" />
      </points>
    </group>
  );
}
