import React, { useRef, useState, useEffect } from 'react';
import { useThree } from '@react-three/fiber';
import { Float, Preload } from '@react-three/drei';
import CustomOrbitControls from './CustomOrbitControls';

import HeavyContent from './HeavyContent';
import PostProcessing from './PostProcessing';
import LightContent from './LightContent';

export default function Experience({ synthwaveMode, asciiMode, fov, audioData }) { // Add audioData prop
  const { viewport, camera } = useThree();
  const waterRef = useRef();
  const grassRef = useRef();
  const iceGroundRef = useRef();
  const scale = Math.min(viewport.width / 5, viewport.height / 5);

  useEffect(() => {
    if (camera && fov) {
      camera.fov = fov;
      camera.updateProjectionMatrix();
    }
  }, [camera, fov]);

  useEffect(() => {
    if (camera) {
      camera.position.set(-3, 1.5, 4);
      camera.lookAt(0, 0, 0);
    }
  }, [camera]);

  return (
    <>
      <color args={synthwaveMode ? ['#ff69b4'] : ['#141414']} attach="background" />

      <CustomOrbitControls 
        enabled={true}
        minAzimuthAngle={-Math.PI / 2}
        maxAzimuthAngle={Math.PI / 2}
        minPolarAngle={Math.PI / 4}
        maxPolarAngle={Math.PI / 2}
        minDistance={3}
        maxDistance={10}
        rotationSensitivity={0.007}
        zoomSensitivity={0.001}
        dampingFactor={0.05}
      />

      <Float rotationIntensity={0.4}>
        <group scale={scale}>
          <LightContent
            synthwaveMode={synthwaveMode}
            asciiMode={asciiMode}
            audioData={audioData} 
          />
          <HeavyContent
            waterRef={waterRef}
            grassRef={grassRef}
            iceGroundRef={iceGroundRef}
            synthwaveMode={synthwaveMode}
            scale={scale}
          />
        </group>
      </Float>

      <PostProcessing asciiMode={asciiMode} synthwaveMode={synthwaveMode} />

      <Preload all />
    </>
  );
}