import React, { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';
import { useThree, useFrame } from '@react-three/fiber';
import { createNoise3D, createNoise2D } from 'simplex-noise';
import { isMobile } from 'react-device-detect';

const PLANE_PARAMS = {
  width: 120,
  height: 111,
  segments: 40,
};

export default function Analyzer({ synthwaveMode, audioData }) {
  const { scene } = useThree();
  const groupRef = useRef();
  const ballRef = useRef();
  const plane1Ref = useRef();
  const plane2Ref = useRef();
  const plane3Ref = useRef();
  const noise3D = useRef(createNoise3D());
  const noise2D = useRef(createNoise2D());
  const baseVerticesRef = useRef(null);
  const basePlaneVerticesRef = useRef(null);

  const [ballColor] = useState(synthwaveMode ? 0xff00ff : 0xff00ee);
  const [planeColor] = useState(synthwaveMode ? 0xff00ff : 0x6904ce);

  const vertexShader = `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `;

  const planeFragmentShader = `
    uniform vec3 color1;
    uniform vec3 color2;
    varying vec2 vUv;
    void main() {
      gl_FragColor = vec4(mix(color1, color2, vUv.y), 1.0);
    }
  `;

  const ballFragmentShader = `
    uniform vec3 innerColor;
    uniform vec3 outerColor;
    varying vec2 vUv;
    void main() {
      float dist = length(vUv - 0.5);
      gl_FragColor = vec4(mix(innerColor, outerColor, dist * 2.0), 1.0);
    }
  `;

  const plane3FragmentShader = `
    uniform vec3 color1;
    uniform vec3 color2;
    varying vec2 vUv;
    uniform float time;

    float random(vec2 p) {
      return fract(sin(dot(p, vec2(127.1, 311.7))) * 43758.5453123);
    }

    float noise(vec2 p) {
      vec2 i = floor(p);
      vec2 f = fract(p);
      f = f * f * (3.0 - 2.0 * f);
      return mix(mix(random(i), random(i + vec2(1.0, 0.0)), f.x),
                 mix(random(i + vec2(0.0, 1.0)), random(i + vec2(1.0, 1.0)), f.x), f.y);
    }

    void main() {
      float n = noise(vUv * 10.0 + time * 0.1);
      gl_FragColor = vec4(mix(color1, color2, n), 1.0);
    }
  `;

  useEffect(() => {
    if (!groupRef.current) return;

    const planeGeometry = new THREE.PlaneGeometry(
      PLANE_PARAMS.width,
      PLANE_PARAMS.height,
      PLANE_PARAMS.segments,
      PLANE_PARAMS.segments
    );

    basePlaneVerticesRef.current = planeGeometry.attributes.position.array.slice();

    const planeMaterial = new THREE.ShaderMaterial({
      vertexShader,
      fragmentShader: planeFragmentShader,
      uniforms: {
        color1: { value: new THREE.Color(planeColor) },
        color2: { value: new THREE.Color(0xffaa00) }
      },
      wireframe: true,
      transparent: true,
      opacity: 0.7,
      blending: THREE.AdditiveBlending,

    });

    const plane2Material = new THREE.ShaderMaterial({
      vertexShader,
      fragmentShader: planeFragmentShader,
      uniforms: {
        color1: { value: new THREE.Color(planeColor) },
        color2: { value: new THREE.Color(0xffaa00) }
      },
      wireframe: false,
      transparent: true,
      opacity: 0.7,
      blending: THREE.AdditiveBlending,
    });

    const plane1 = new THREE.Mesh(planeGeometry.clone(), plane2Material.clone());
    plane1.rotation.x = -0.5 * Math.PI;
    plane1.position.set(0, -1.5, 0);
    plane1Ref.current = plane1;
    groupRef.current.add(plane1);

    const plane2 = new THREE.Mesh(planeGeometry.clone(), planeMaterial.clone());
    plane2.rotation.x = -0.5 * Math.PI;
    plane2.position.set(0, -4.5, 0);
    plane2Ref.current = plane2;
    groupRef.current.add(plane2);

    const plane3Material = new THREE.ShaderMaterial({
      vertexShader,
      fragmentShader: plane3FragmentShader,
      uniforms: {
        color1: { value: new THREE.Color(planeColor) },
        color2: { value: new THREE.Color(0x00ffff) },
        time: { value: 0 }
      },
      wireframe: true,
      transparent: true,
      opacity: 0.7,
      blending: THREE.AdditiveBlending,

    });

    const plane3 = new THREE.Mesh(planeGeometry.clone(), plane3Material);
    plane3.rotation.x = -0.5 * Math.PI;
    plane3.position.set(0, -6, 0);
    plane3Ref.current = plane3;
    groupRef.current.add(plane3);

    if (isMobile) {
      const ballGeometry = new THREE.IcosahedronGeometry(1, 4);
      baseVerticesRef.current = ballGeometry.attributes.position.array.slice();

      const ballMaterial = new THREE.ShaderMaterial({
        vertexShader,
        fragmentShader: ballFragmentShader,
        uniforms: {
          innerColor: { value: new THREE.Color(ballColor) },
          outerColor: { value: new THREE.Color(0x00ffff) }
        },
        wireframe: true,
        blending: THREE.AdditiveBlending,
      });

      const ball = new THREE.Mesh(ballGeometry, ballMaterial);
      ball.position.set(-9, 11, -19);
      ball.scale.set(0.5, 0.5, 0.5);
      ballRef.current = ball;
      groupRef.current.add(ball);
    }

    const ambientLight = new THREE.AmbientLight(0xaaaaaa);
    scene.add(ambientLight);

    const spotLight = new THREE.SpotLight(0xffffff);
    spotLight.intensity = 0.9;
    spotLight.position.set(-1, 4, 2);
    spotLight.castShadow = true;
    scene.add(spotLight);

    groupRef.current.position.set(1, -0.65, 1.6);
    groupRef.current.scale.set(0.1, 0.1, 0.1);

    return () => {
      while (groupRef.current.children.length) {
        groupRef.current.remove(groupRef.current.children[0]);
      }
      scene.remove(ambientLight);
      scene.remove(spotLight);
    };
  }, [synthwaveMode, scene]);

  const makeRoughBall = (mesh, bassFr, treFr) => {
    if (!mesh?.geometry || !baseVerticesRef.current) return;

    const positions = mesh.geometry.attributes.position;
    const vertex = new THREE.Vector3();

    for (let i = 0; i < positions.count; i++) {
      vertex.fromArray(baseVerticesRef.current, i * 3);
      vertex.normalize();

      const offset = 1;
      const amp = 7;
      const time = Date.now();
      const rf = 0.00001;

      let distance = offset + bassFr + noise3D.current(vertex.x + time * rf * 7, vertex.y + time * rf * 8, vertex.z + time * rf * 9) * amp * treFr;
      distance = Math.max(distance, -0.71);

      vertex.multiplyScalar(distance);
      positions.setXYZ(i, vertex.x, vertex.y, vertex.z);
    }

    positions.needsUpdate = true;
    mesh.geometry.computeVertexNormals();
  };

  const makeRoughGround = (mesh, distortionFr) => {
    if (!mesh?.geometry || !basePlaneVerticesRef.current) return;

    const positions = mesh.geometry.attributes.position;
    const vertex = new THREE.Vector3();

    for (let i = 0; i < positions.count; i++) {
      vertex.fromArray(basePlaneVerticesRef.current, i * 3);

      const amp = 2;
      const time = Date.now();
      let distance = (noise2D.current(vertex.x + time * 0.0003, vertex.y + time * 0.0001) + 0) * distortionFr * amp;
      distance = Math.min(distance, -0.9);
      positions.setXYZ(i, vertex.x, vertex.y, distance);
    }

    positions.needsUpdate = true;
    mesh.geometry.computeVertexNormals();
  };

  const makeFrequencyVisualizerPlane = (mesh, overlayMesh, frequencyArray) => {
    if (!mesh?.geometry || !basePlaneVerticesRef.current) return;

    const positions = mesh.geometry.attributes.position;
    const overlayPositions = overlayMesh.geometry.attributes.position;
    const vertex = new THREE.Vector3();
    const overlayVertex = new THREE.Vector3();
    const numFrequencies = frequencyArray.length;
    const segmentWidth = PLANE_PARAMS.width / PLANE_PARAMS.segments;

    for (let i = 0; i < positions.count; i++) {
      vertex.fromArray(basePlaneVerticesRef.current, i * 3);

      const segmentIndex = Math.floor((vertex.x + PLANE_PARAMS.width / 2) / segmentWidth);
      const freqValue = frequencyArray[Math.min(segmentIndex, numFrequencies - 1)];

      const height = modulate(freqValue, 0, 255, 0, 10);
      vertex.z = height;
      overlayVertex.copy(vertex).setZ(height + 0.5);

      positions.setXYZ(i, vertex.x, vertex.y, vertex.z);
      overlayPositions.setXYZ(i, overlayVertex.x, overlayVertex.y, overlayVertex.z);
    }

    positions.needsUpdate = true;
    overlayPositions.needsUpdate = true;
    mesh.geometry.computeVertexNormals();
    overlayMesh.geometry.computeVertexNormals();
  };

  useFrame(({ clock }) => {
    if (plane3Ref.current) {
      plane3Ref.current.material.uniforms.time.value = clock.getElapsedTime();
    }

    if (!audioData) return;

    const { dataArray } = audioData;
    if (!dataArray || !dataArray.length) return;

    const lowerHalfArray = dataArray.slice(0, dataArray.length / 2 - 1);
    const upperHalfArray = dataArray.slice(dataArray.length / 2 - 1, dataArray.length - 1);

    const lowerMax = Math.max(...lowerHalfArray);
    const lowerAvg = lowerHalfArray.reduce((a, b) => a + b, 0) / lowerHalfArray.length;
    const upperMax = Math.max(...upperHalfArray);
    const upperAvg = upperHalfArray.reduce((a, b) => a + b, 0) / upperHalfArray.length;

    const lowerMaxFr = lowerMax / lowerHalfArray.length;
    const lowerAvgFr = lowerAvg / lowerHalfArray.length;
    const upperMaxFr = upperMax / upperHalfArray.length;
    const upperAvgFr = upperAvg / upperHalfArray.length;

    if (ballRef.current) {
      makeRoughBall(
        ballRef.current,
        modulate(Math.pow(lowerMaxFr, 0.8), 0, 1, 1, 15),
        modulate(upperAvgFr, 0, 1, 1, 8)
      );
    }

    makeRoughGround(plane1Ref.current, modulate(upperAvgFr, 0, 1, 1, 5));
    makeFrequencyVisualizerPlane(plane3Ref.current, plane2Ref.current, dataArray);
  });

  const modulate = (val, minVal, maxVal, outMin, outMax) => {
    const fr = (val - minVal) / (maxVal - minVal);
    const delta = outMax - outMin;
    return outMin + fr * delta;
  };

  return <group ref={groupRef} />;
}
