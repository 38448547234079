// Scene.jsx
// SPAGHETTI CODE WARNING!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
import React, { useRef, useEffect } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import { Environment, useGLTF, Text } from '@react-three/drei';
import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';
import * as THREE from 'three';
import { isMobile } from 'react-device-detect'; 
import Analyzer from './Analyzer';

const CUBE_COUNT = 50;
const CUBE_SPREAD = 4;
const ROTATION_SPEED = 0.5;
const FLOAT_SPEED = 0.3;

const SCREEN_SIZE = {
  width: 1024,
  height: 670
};

export default function Scene({
  iframeLink,
  synthwaveMode,
  sectionTitle,
  asciiMode,
  audioData,
}) {
  const computer = useGLTF(
    'https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/macbook/model.gltf'
  );

  const groupRef = useRef();
  const cubesRef = useRef();
  const cssRenderer = useRef();
  const cssObject = useRef();
  const { scene, camera, size } = useThree();
  const currentIframeLink = iframeLink; // for the future, i had another site i wanted to put here for synth mode but i changed my mind. this has functionality to support multiple links to a site, but there's a default one. 

  useEffect(() => {
    if (asciiMode && synthwaveMode) {
      cubesRef.current = new THREE.Group();
      
      for (let i = 0; i < CUBE_COUNT; i++) {
        const size = 0.2 + Math.random() * 0.3; 
        const geometry = new THREE.BoxGeometry(size, size, size);
        const material = new THREE.MeshPhysicalMaterial({
          color: synthwaveMode ? '#ff00ff' : '#ffffff',
          metalness: 0.8,
          roughness: 0.2,
          transmission: 0.5,
          thickness: 0.5,
        });

        const cube = new THREE.Mesh(geometry, material);
        
        cube.position.set(
          (Math.random() - 0.5) * CUBE_SPREAD,
          (Math.random() - 0.5) * CUBE_SPREAD,
          (Math.random() - 0.5) * CUBE_SPREAD - 2 
        );
        
        // Store animation parameters
        cube.userData = {
          originalY: cube.position.y,
          phaseX: Math.random() * Math.PI * 2,
          phaseY: Math.random() * Math.PI * 2,
          phaseZ: Math.random() * Math.PI * 2,
          speed: 0.5 + Math.random() * ROTATION_SPEED,
          floatSpeed: (0.5 + Math.random()) * FLOAT_SPEED
        };
        
        cubesRef.current.add(cube);
      }
      
      scene.add(cubesRef.current);
    } else if (cubesRef.current) {
      scene.remove(cubesRef.current);
      cubesRef.current = null;
    }

    return () => {
      if (cubesRef.current) {
        scene.remove(cubesRef.current);
        cubesRef.current = null;
      }
    };
  }, [asciiMode, synthwaveMode, scene]);

  // Initialize CSS3DRenderer once
  useEffect(() => {
    if (!cssRenderer.current) {
      cssRenderer.current = new CSS3DRenderer();
      cssRenderer.current.setSize(size.width, size.height);
      cssRenderer.current.domElement.style.position = 'absolute';
      cssRenderer.current.domElement.style.top = '0';
      cssRenderer.current.domElement.style.left = '0';
      cssRenderer.current.domElement.style.pointerEvents = 'none';
      cssRenderer.current.domElement.style.zIndex = '1';

      const appContainer = document.getElementById('app-container');
      if (appContainer && !appContainer.contains(cssRenderer.current.domElement)) {
        appContainer.appendChild(cssRenderer.current.domElement);
      }
    }

    return () => {
      const appContainer = document.getElementById('app-container');
      if (appContainer && cssRenderer.current?.domElement) {
        appContainer.removeChild(cssRenderer.current.domElement);
      }
      cssRenderer.current = null;
    };
  }, []);

  useEffect(() => {
    if (cssRenderer.current) {
      cssRenderer.current.setSize(size.width, size.height);
    }
  }, [size]);

  // Create and manage iframe
  useEffect(() => {
    const shouldDisableIframe = asciiMode || isMobile; // Disable iframe if asciiMode is active or on mobile, because mobile cant handle it when theres a new tab bar, for some unknown god forsaken reason.
    if (shouldDisableIframe) {
      console.log('Disabling iframe because asciiMode is active or on a mobile device.');
      if (cssObject.current && groupRef.current) {
        groupRef.current.remove(cssObject.current);
        cssObject.current = null;
      }
      return;
    }

    console.log('Creating iframe:', currentIframeLink);
    
    // Clean up previous iframe if it exists
    if (cssObject.current && groupRef.current) {
      groupRef.current.remove(cssObject.current);
      cssObject.current = null;
    }

    const container = document.createElement('div');
    container.style.width = `${SCREEN_SIZE.width}px`;
    container.style.height = `${SCREEN_SIZE.height}px`;
    container.style.background = '#ffffff';
    container.id = 'iframe-container';

    const iframe = document.createElement('iframe');
    iframe.src = currentIframeLink;
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.style.border = 'none';
    iframe.style.pointerEvents = 'auto';
    
    container.appendChild(iframe);

    const object = new CSS3DObject(container);
    cssObject.current = object;

    object.position.set(0, 1.57, -1.4);
    object.rotation.x = -0.256;
    object.scale.set(0.002905, 0.00277, 0.00277);

    if (groupRef.current) {
      console.log('Adding iframe to group.');
      groupRef.current.add(object);
    }

    return () => {
      if (cssObject.current && groupRef.current) {
        groupRef.current.remove(cssObject.current);
        cssObject.current = null;
      }
    };
  }, [currentIframeLink, scene, asciiMode]);

  // Animate cubes and render CSS3D
  useFrame((state) => {
    const time = state.clock.getElapsedTime();

    if (cubesRef.current) {
      cubesRef.current.children.forEach((cube) => {
        const { originalY, phaseX, phaseY, phaseZ, speed, floatSpeed } = cube.userData;
        
        // Individual rotation
        cube.rotation.x += 0.01 * speed;
        cube.rotation.y += 0.02 * speed;
        cube.rotation.z += 0.01 * speed;
        
        // Floating motion
        cube.position.y = originalY + Math.sin(time * floatSpeed + phaseY) * 0.3;
      });

      // Subtle group rotation
      cubesRef.current.rotation.y += 0.001;
    }

    if (cssRenderer.current) {
      cssRenderer.current.render(scene, camera);
    }
  });

  return (
    <>
      <Environment files="hilky.hdr" background />
      <group ref={groupRef} position-y={-1.2}>
        <primitive object={computer.scene} scale={[1, 1, 1]} />
      </group>

      {(true) && (
        <Analyzer 
          synthwaveMode={synthwaveMode}
          audioData={audioData}
        />
      )}
      
      <Text
        font="/PixelifySans-Regular.ttf"
        fontSize={0.08}
        position={[-2.55, 0.7, 0]}
        rotation-x={-0.5}
        color={synthwaveMode ? '#ff00ff' : 'magenta'}
        maxWidth={2}
      >
        {sectionTitle}
      </Text>
    </>
  );
}
