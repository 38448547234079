// CyclingWorkPlaces.jsx

import React, { useRef, useState } from 'react';
import { Text } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';

export default function CyclingWorkPlaces({ position, ...props }) {
  const workPlaces = ['ex-EA', 'ex-NASA', 'ex-Merck', 'Google SPS', "L'SPACE"];
  const colors = ['#FF4500', '#4169E1', '#228B22', '#4285F4', '#FF6900'];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(-1);
  const textRef = useRef();
  const prevTextRef = useRef();
  const circleRef = useRef();

  const [animationProgress, setAnimationProgress] = useState(0);

  useFrame((state, delta) => {
    setAnimationProgress((prev) => {
      const next = prev + delta * 0.5;
      if (next >= 1) {
        setPrevIndex(currentIndex);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % workPlaces.length);
        return 0;
      }
      return next;
    });

    if (textRef.current) {
      textRef.current.position.y =
        Math.sin(animationProgress * Math.PI * 0.5) * 0.1;
      textRef.current.material.opacity = Math.sin(
        animationProgress * Math.PI * 0.5
      );
    }

    if (prevTextRef.current) {
      prevTextRef.current.position.y =
        0.1 - Math.sin(animationProgress * Math.PI * 0.5) * 0.1;
      prevTextRef.current.material.opacity = Math.cos(
        animationProgress * Math.PI * 0.5
      );
    }

    if (circleRef.current) {
      const currentColor = new THREE.Color(colors[currentIndex]);
      const prevColor = new THREE.Color(
        colors[prevIndex !== -1 ? prevIndex : colors.length - 1]
      );
      circleRef.current.material.color
        .copy(prevColor)
        .lerp(currentColor, Math.sin(animationProgress * Math.PI * 0.5));
    }
  });

  return (
    <group position={position} {...props}>
      <mesh ref={circleRef}>
        <ringGeometry args={[0.3, 0.33, 32]} />
        <meshBasicMaterial
          color={colors[currentIndex]}
          transparent
          opacity={0.8}
        />
      </mesh>
      <group position={[0, -0.05, 0.01]}>
        <Text
          ref={textRef}
          font="/PixelifySans-Regular.ttf"
          fontSize={0.1}
          maxWidth={0.6}
          textAlign="center"
          color={colors[currentIndex]}
          anchorX="center"
          anchorY="middle"
        >
          {workPlaces[currentIndex]}
        </Text>
        {prevIndex !== -1 && (
          <Text
            ref={prevTextRef}
            font="/PixelifySans-Regular.ttf"
            fontSize={0.1}
            maxWidth={0.6}
            textAlign="center"
            color={colors[prevIndex]}
            anchorX="center"
            anchorY="middle"
          >
            {workPlaces[prevIndex]}
          </Text>
        )}
      </group>
    </group>
  );
}
