import React, { useEffect, useState } from 'react';

export default function LoadingScreen({ progress }) {
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    if (progress === 100) {
      const timeout = setTimeout(() => setFadeOut(true), 500);
      return () => clearTimeout(timeout);
    }
  }, [progress]);

  return (
    <div id="initial-loading-screen" className={`loading-screen ${fadeOut ? 'fade-out' : ''}`}>
      <div className="loading-screen-text">
        <h2>Loading... {progress.toFixed(0)}%</h2>
        <p>
          This site works best in landscape mode, ideally on a desktop (But you get to see a cool audio visualizer on mobile!). <br></br><br></br> You can tap "Retro" for cool postprocessing effects (maybe it'll be ascii???) and tap "About/Work" to learn more about me.  <br></br><br></br> 3D model credits are in the project's README.  <br></br><br></br> Enjoy! :D   
        </p>
      </div>
      <div className="loading-screen-mosaic">
        <div className="mosaic-loader">
          {Array.from({ length: 16 }).map((_, index) => {
            const i = Math.floor(index / 4);
            const j = index % 4;
            const delay = (i + j) * 0.1;
            return (
              <div
                key={`${i}-${j}`}
                className="mosaic-cell"
                style={{ animationDelay: `${delay}s` }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}