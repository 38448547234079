// PostProcessing.jsx

import React, { useState, useEffect } from 'react';
import {
  EffectComposer,
  Pixelation,
  Glitch,
  Noise,
  Bloom,
  ChromaticAberration,
  Scanline,
  Vignette,
  Sepia,
} from '@react-three/postprocessing';
import { GlitchMode } from 'postprocessing';
import { AsciiRenderer } from '@react-three/drei';

const PreloadedEffects = ({ visible = false }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // I need this and dont know why. I'm sorry God.
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  if (!isLoaded) return null;

  return (
    <EffectComposer enabled={visible}>
      <Pixelation granularity={2} />
      <Glitch
        delay={[1.5, 3.5]}
        duration={[0.005, 0.01]}
        mode={GlitchMode.SPORADIC}
      />
      <Noise opacity={0.05} />
      <ChromaticAberration offset={[0.002, 0.002]} />
      <Scanline density={1.0} />
      <Vignette eskil={false} offset={0.2} darkness={1.2} />
      <Bloom
        luminanceThreshold={0.3}
        luminanceSmoothing={0.9}
        intensity={0.5}
      />
    </EffectComposer>
  );
};

export default function PostProcessing({ asciiMode, synthwaveMode }) {
  return (
    <>
      {asciiMode && (
        <AsciiRenderer fgColor="white" bgColor="black" characters=" .:-=+*#%@" />
      )}
      <PreloadedEffects visible={synthwaveMode} />
    </>
  );
}