import React from 'react';

export default function SlidersAndFov({ 
  volume, 
  onVolumeChange,
  fov, 
  onFovChange,
  audioEnabled,
  onAudioEnable,
  isChecked 
}) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
      }}
    >
      {/* VFOV Control */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <label
          htmlFor="vfovSlider"
          style={{
            fontFamily: 'PixelifySans, sans-serif',
            color: '#fff',
            whiteSpace: 'nowrap',
          }}
        >
          VFOV
        </label>
        <input
          id="vfovSlider"
          type="range"
          min="30"
          max="90"
          step="1"
          value={fov}
          onChange={onFovChange}
          style={{
            width: '80px',
          }}
        />
      </div>

      {/* Audio Control */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        {!audioEnabled ? (
          <>
            <label
              htmlFor="audioCheckbox"
              style={{
                fontFamily: 'PixelifySans, sans-serif',
                color: '#fff',
                whiteSpace: 'nowrap',
              }}
            >
              Enable Audio
            </label>
            <input
              id="audioCheckbox"
              type="checkbox"
              checked={isChecked} // Add this
              onChange={onAudioEnable}
              style={{
                cursor: 'pointer'
              }}
            />
          </>
        ) : (
          <>
            <label
              htmlFor="volumeSlider"
              style={{
                fontFamily: 'PixelifySans, sans-serif',
                color: '#fff',
                whiteSpace: 'nowrap',
              }}
            >
              Volume
            </label>
            <input
              id="volumeSlider"
              type="range"
              min="0"
              max=".75"
              step="0.01"
              value={volume}
              onChange={onVolumeChange}
              style={{
                width: '80px',
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}
