import { useThree, useFrame } from '@react-three/fiber';
import { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';

export default function CustomOrbitControls({ 
  enabled = true,
  minAzimuthAngle = -Math.PI / 2,
  maxAzimuthAngle = Math.PI / 2,
  minPolarAngle = Math.PI / 4,
  maxPolarAngle = Math.PI / 2,
  minDistance = 3,
  maxDistance = 10,
  rotationSensitivity = 0.005,
  zoomSensitivity = 0.0008,
  pinchZoomSensitivity = 0.02,
  dampingFactor = 0.035
}) {
  const { camera } = useThree();
  const isDragging = useRef(false);
  const lastMouseX = useRef(0);
  const lastMouseY = useRef(0);
  const lastTouchDistance = useRef(null);
  const spherical = useRef(new THREE.Spherical());
  const targetSpherical = useRef(new THREE.Spherical());
  const velocity = useRef({ theta: 0, phi: 0 });
  const [isOverIframe, setIsOverIframe] = useState(false);
  const isInitialized = useRef(false);

  const getIframe = () => document.querySelector('#iframe-container iframe');

  const isUIElement = (element) => {
    if (!element) return false;
    
    const isButton = element.tagName === 'BUTTON' || 
                    element.closest('button') !== null;
    
    const isInput = element.tagName === 'INPUT' || 
                   element.closest('input') !== null;
    
    const isLabel = element.tagName === 'LABEL' || 
                   element.closest('label') !== null;
    
    const isSliderContainer = element.closest('[style*="gap: 8px"]') !== null;
    
    const style = window.getComputedStyle(element);
    const isClickable = style.cursor === 'pointer';

    return isButton || isInput || isLabel || isSliderContainer || isClickable;
  };

  useEffect(() => {
    if (!isInitialized.current) {
      camera.position.set(-3, 1.5, 4);
      camera.lookAt(0, 0, 0);

      const vector = new THREE.Vector3().copy(camera.position);
      spherical.current.setFromVector3(vector);
      
      targetSpherical.current.radius = spherical.current.radius;
      targetSpherical.current.phi = spherical.current.phi;
      targetSpherical.current.theta = spherical.current.theta;

      isInitialized.current = true;
    }
  }, [camera]);

  const getTouchDistance = (touch1, touch2) => {
    const dx = touch1.clientX - touch2.clientX;
    const dy = touch1.clientY - touch2.clientY;
    return Math.sqrt(dx * dx + dy * dy);
  };

  const handleTouchStart = (e) => {
    if ((!isDragging.current && isOverIframe) || (!isDragging.current && isUIElement(e.target))) return;
    
    if (e.touches.length === 2) {
      lastTouchDistance.current = getTouchDistance(e.touches[0], e.touches[1]);
    } else if (e.touches.length === 1) {
      handlePointerDown(e.touches[0]);
    }
  };

  const handleTouchMove = (e) => {
    if (!isDragging.current && (isOverIframe || isUIElement(e.target))) return;

    if (e.touches.length === 2) {
      const currentDistance = getTouchDistance(e.touches[0], e.touches[1]);
      
      if (lastTouchDistance.current !== null) {
        const delta = (lastTouchDistance.current - currentDistance) * pinchZoomSensitivity;
        const newRadius = targetSpherical.current.radius + delta;
        targetSpherical.current.radius = THREE.MathUtils.clamp(
          newRadius,
          minDistance,
          maxDistance
        );
      }
      
      lastTouchDistance.current = currentDistance;
    } else if (e.touches.length === 1) {
      handlePointerMove(e.touches[0]);
    }
  };

  const handleTouchEnd = () => {
    lastTouchDistance.current = null;
    handlePointerUp();
  };

  const handleWheel = (e) => {
    if (isOverIframe || isUIElement(e.target)) return;
    const zoomDelta = e.deltaY * zoomSensitivity;
    const newRadius = targetSpherical.current.radius + zoomDelta;
    targetSpherical.current.radius = THREE.MathUtils.clamp(
      newRadius,
      minDistance,
      maxDistance
    );
  };

  const handlePointerDown = (e) => {
    if (!enabled || (!isDragging.current && isOverIframe) || (!isDragging.current && isUIElement(e.target))) return;
    
    isDragging.current = true;
    lastMouseX.current = e.clientX;
    lastMouseY.current = e.clientY;
    velocity.current = { theta: 0, phi: 0 };

    const iframe = getIframe();
    if (iframe) {
      iframe.style.pointerEvents = 'none';
    }
  };

  const handlePointerMove = (e) => {
    if (!isDragging.current || !enabled) return;

    const deltaX = -(e.clientX - lastMouseX.current) * rotationSensitivity;
    const deltaY = -(e.clientY - lastMouseY.current) * rotationSensitivity;

    velocity.current.theta = deltaX * 0.9 + velocity.current.theta * 0.1;
    velocity.current.phi = deltaY * 0.9 + velocity.current.phi * 0.1;

    targetSpherical.current.phi = THREE.MathUtils.clamp(
      targetSpherical.current.phi + velocity.current.phi,
      minPolarAngle,
      maxPolarAngle
    );

    targetSpherical.current.theta = THREE.MathUtils.clamp(
      targetSpherical.current.theta + velocity.current.theta,
      minAzimuthAngle,
      maxAzimuthAngle
    );

    lastMouseX.current = e.clientX;
    lastMouseY.current = e.clientY;
  };

  const handlePointerUp = () => {
    isDragging.current = false;
    const decay = 0.95;
    velocity.current.theta *= decay;
    velocity.current.phi *= decay;

    const iframe = getIframe();
    if (iframe) {
      iframe.style.pointerEvents = 'auto';
    }
  };

  const handleIframeHover = (entering) => {
    if (!isDragging.current) {
      setIsOverIframe(entering);
      if (entering) {
        velocity.current = { theta: 0, phi: 0 };
      }
    }
  };

  useFrame(() => {
    if (!isInitialized.current) return;

    if (!isDragging.current) {
      targetSpherical.current.theta += velocity.current.theta;
      targetSpherical.current.phi += velocity.current.phi;
      
      velocity.current.theta *= 0.95;
      velocity.current.phi *= 0.95;

      targetSpherical.current.phi = THREE.MathUtils.clamp(
        targetSpherical.current.phi,
        minPolarAngle,
        maxPolarAngle
      );
      targetSpherical.current.theta = THREE.MathUtils.clamp(
        targetSpherical.current.theta,
        minAzimuthAngle,
        maxAzimuthAngle
      );
    }

    spherical.current.phi += (targetSpherical.current.phi - spherical.current.phi) * dampingFactor;
    spherical.current.theta += (targetSpherical.current.theta - spherical.current.theta) * dampingFactor;
    spherical.current.radius += (targetSpherical.current.radius - spherical.current.radius) * dampingFactor;

    const sinPhiRadius = spherical.current.radius * Math.sin(spherical.current.phi);
    camera.position.x = sinPhiRadius * Math.sin(spherical.current.theta);
    camera.position.y = spherical.current.radius * Math.cos(spherical.current.phi);
    camera.position.z = sinPhiRadius * Math.cos(spherical.current.theta);

    camera.lookAt(0, 0, 0);
  });

  useEffect(() => {
    if (!enabled) return;

    window.addEventListener('touchstart', handleTouchStart, { passive: false });
    window.addEventListener('touchmove', handleTouchMove, { passive: false });
    window.addEventListener('touchend', handleTouchEnd);
    window.addEventListener('touchcancel', handleTouchEnd);
    window.addEventListener('pointerdown', handlePointerDown);
    window.addEventListener('pointermove', handlePointerMove);
    window.addEventListener('pointerup', handlePointerUp);
    window.addEventListener('pointercancel', handlePointerUp);
    window.addEventListener('wheel', handleWheel, { passive: true });

    let iframeContainer;
    let cleanupIframeListeners;
    const checkIframeInterval = setInterval(() => {
      const iframe = getIframe();
      if (iframe) {
        iframeContainer = iframe.parentElement;
        iframeContainer.addEventListener('mouseenter', () => handleIframeHover(true));
        iframeContainer.addEventListener('mouseleave', () => handleIframeHover(false));

        cleanupIframeListeners = () => {
          iframeContainer.removeEventListener('mouseenter', () => handleIframeHover(true));
          iframeContainer.removeEventListener('mouseleave', () => handleIframeHover(false));
        };

        clearInterval(checkIframeInterval);
      }
    }, 100); // Check every 100ms

    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleTouchEnd);
      window.removeEventListener('touchcancel', handleTouchEnd);
      window.removeEventListener('pointerdown', handlePointerDown);
      window.removeEventListener('pointermove', handlePointerMove);
      window.removeEventListener('pointerup', handlePointerUp);
      window.removeEventListener('pointercancel', handlePointerUp);
      window.removeEventListener('wheel', handleWheel);

      if (cleanupIframeListeners) {
        cleanupIframeListeners();
      }

      clearInterval(checkIframeInterval);
    };
  }, [enabled]);

  return null;
}
